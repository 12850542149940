import '@fortawesome/fontawesome-free/css/all.css';
import '../styles/index.scss';
import 'slick-carousel/slick/slick.css';
// import 'aos/src/sass/aos.scss';
// import AOS from 'aos';

import $ from 'jquery';
window.$ = $;
import slick from 'slick-carousel';


const sliderConfig = {
  'main': {
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow: $('#main-slider-prev'),
    nextArrow: $('#main-slider-next'),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  },
  'feedback': {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    customPaging: () => '<button class="custom-pager"></button>'
  }
};

$('.slider').slick( sliderConfig.main );
$('.feedback-slider').slick( sliderConfig.feedback );

ScrollReveal().reveal('.section', { duration: 1500, distance: '0px' });


$('.header__nav a[href^="#"]').on('click', function(event) {
  var target = $(this.getAttribute('href'));
  if( target.length ) {
      event.preventDefault();
      $('html, body').stop().animate({
          scrollTop: target.offset().top
      }, 1000);
  }
});

// AOS.init();

